import * as supabase from '@/libs/supabase'
import { UpVote } from '@/types/room'
import { definitions } from '@/types/dto'

export const deserializeUpVote = (upVote: definitions['up_votes']): UpVote => ({
  trackId: upVote.track_id,
  ownerId: upVote.owner_id,
})

export const get = async (roomId: number) => {
  const res = await supabase.upvotes().select('*').eq('room_id', roomId)
  return (res.data ?? []).map(deserializeUpVote)
}

export type VoteCallback = (vote: UpVote) => void
export const subscribeToUpVotes = async (
  roomId: number,
  onNewUpVote: VoteCallback,
  onUpVoteDeleted: VoteCallback
) => {
  return supabase
    .upvotes({ roomId })
    .on('*', payload => {
      const type = payload.eventType
      const isDelete = type === 'DELETE'
      const load = isDelete ? payload.old : payload.new
      if (load.room_id === roomId) {
        const deserialized = deserializeUpVote(load)
        const fun = isDelete ? onUpVoteDeleted : onNewUpVote
        fun(deserialized)
      }
    })
    .subscribe()
}
