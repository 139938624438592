import * as supabase from '@/libs/supabase'
import { definitions } from '@/types/dto'
import { PaidTrack } from '@/types/room'

export const deserializePaidTrack = (
  paidTrack: definitions['paid_tracks']
): PaidTrack => ({
  trackId: paidTrack.track_id,
  ownerId: paidTrack.owner_id,
})

export const get = async (roomId: number) => {
  const res = await supabase
    .paidTracks()
    .select('*')
    .eq('room_id', roomId)
    .order('created_at', { ascending: true })
  return (res.data ?? []).map(deserializePaidTrack)
}

export type SubscribeCallback = (newTrack: PaidTrack[]) => void
export const subscribe = async (roomId: number, cb: SubscribeCallback) => {
  let data = await get(roomId)
  cb(data)
  const deserialize = deserializePaidTrack
  const subscription = supabase
    .paidTracks({ roomId })
    .on('INSERT', payload => cb((data = [...data, deserialize(payload.new)])))
    .subscribe()
  subscription.onError(async () => cb((data = await get(roomId))))
  return subscription
}
