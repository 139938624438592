import { Effect, Action } from '@/store/types'
import { Room, UpVote, PaidTrack, Track, Music } from '@/types/room'
import * as services from '@/services'

export const updateRoom = (room: Room | null): Action => {
  return { type: 'music/UPDATE_ROOM', room }
}

export const updateUpVotes = (upVotes: UpVote[]): Action => {
  return { type: 'music/UPDATE_UPVOTES', upVotes }
}

export type OnVote = { roomId: number; trackId: number; ownerId: string }
export const onVote = (
  upOrDown: 'up' | 'down',
  params: OnVote
): Effect<boolean> => {
  return async dispatch => {
    const { trackId, ownerId } = params
    const action = upOrDown === 'up' ? addUpVote : delUpVote
    dispatch(action({ ownerId, trackId }))
    const { upVote, downVote } = services.room.tracks
    const serverHandler = upOrDown === 'up' ? upVote : downVote
    const data = await serverHandler(params)
    if (data.length === 0) {
      const action = upOrDown === 'up' ? delUpVote : addUpVote
      dispatch(action({ ownerId, trackId }))
      return false
    } else {
      return true
    }
  }
}

export const addDelVote = (upOrDown: 'up' | 'down') => {
  return (vote: UpVote): Action => {
    const add = 'music/ADD_UP_VOTE'
    const down = 'music/DELETE_UP_VOTE'
    const type = upOrDown === 'up' ? add : down
    return { type, vote }
  }
}
export const addUpVote = addDelVote('up')
export const delUpVote = addDelVote('down')

export const updatePaidTracks = (paidTracks: PaidTrack[]): Action => {
  return { type: 'music/UPDATE_PAID_TRACKS', paidTracks }
}

export const updateTracks = (tracks: Track[]): Effect<void> => {
  return (dispatch, getState) => {
    const { settings, music } = getState()
    if (services.user.isRoomOwner(settings.user?.id, music.room?.ownerId)) {
      tracks.forEach(track => services.musics.enqueue(track.serviceId))
    }
    dispatch({ type: 'music/UPDATE_TRACKS', tracks })
  }
}

export const removeTrack = (track: Track): Effect<void> => {
  return async (dispatch, getState) => {
    const { room } = getState().music
    dispatch({ type: 'music/REMOVE_TRACK', track })
    if (room?.id) services.room.tracks.remove(room.id, track.id)
  }
}

export const adminAddTrack = (music: Music): Effect<void> => {
  return async (dispatch, getState) => {
    const state = getState()
    const { room } = state.music
    const { user } = state.settings
    if (!room) return
    if (services.user.isRoomOwner(user?.id, room.ownerId)) {
      const ownerId = user!.id
      const params = { musics: [music], room, ownerId }
      const tracks = await services.room.tracks.addPlaylist(params)
      for (const track of tracks) {
        dispatch({ type: 'music/ADD_SINGLE_TRACK', track })
      }
    }
  }
}
