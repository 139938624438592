import * as supabase from '@/libs/supabase'

export type SpotifyState = {
  isJamhousePlaylist: boolean
}

export const redeemAuthorizationCode = async (code: string) => {
  const token = supabase.client.auth.session()?.access_token ?? ''
  const endpoint = `/api/spotify/redeem-code?code=${encodeURIComponent(code)}`
  const response = await fetch(endpoint, { headers: { token } })
  return response.json()
}

export const pingSpotify = async (
  roomId: number,
  ownerId: string,
  onData: (isJamhousePlaylist: boolean) => void
): Promise<void> => {
  const params = new URLSearchParams({ roomId: roomId.toString(), ownerId })
  const response = await fetch(`/api/spotify/ping?${params.toString()}`)
  const data = (await response.json()) as SpotifyState
  onData(data.isJamhousePlaylist)
}

export const getMyPlaylists = async () => {
  const token = supabase.client.auth.session()?.access_token ?? ''
  const endpoint = `/api/spotify/me/playlists`
  const response = await fetch(endpoint, { headers: { token } })
  return response.json()
}

export const createJamhousePlaylists = async (playlistsToCreate: string[]) => {
  const endpoint = `/api/spotify/me/playlists/create`
  const headers = { token: supabase.client.auth.session()?.access_token ?? '' }
  const body = JSON.stringify({ playlistsToCreate })
  const response = await fetch(endpoint, { method: 'POST', headers, body })
  return response.json()
}
