import * as supabase from '@/libs/supabase'
import { definitions } from '@/types/dto'
import { User } from '@/types/user'

export const deserializeUser = (user: definitions['users']): User => {
  return {
    id: user.id,
    stripeCustomerId: user.stripe_customer_id,
  }
}

export const createUser = async (id?: string): Promise<User> => {
  const query = supabase.users().upsert({ id })
  const data = await supabase.query(query)
  const createdUser = data[0] as definitions['users']
  return deserializeUser(createdUser)
}

export const insertToken = async (userId: string, roomId: string) => {
  const params = new URLSearchParams({ userId, roomId })
  const response = await fetch(`/api/insertToken?${params.toString()}`)
  const data = await response.json()
  return data.message
}

export const getTotalTokens = async (uid: string): Promise<number> => {
  const { data, error } = await supabase.tokens().select().eq('owner_id', uid)
  if (error) console.error(error)
  return data?.shift()?.total ?? 0
}

export const findUserById = async (uid: string): Promise<User | null> => {
  const { data, error } = await supabase.users().select().eq('id', uid)
  if (error) console.error(error)
  if (!data || data.length === 0) return null
  return deserializeUser(data[0])
}

export const isRoomOwner = (userId?: string, roomId?: string) =>
  roomId !== undefined && userId === roomId
