import * as supabase from '@/libs/supabase'

const MAX_APPLIED_DISCOUNT = 0.4

export const computePrice = (totalTokens: number) => {
  const percentage = 1 - Math.min(MAX_APPLIED_DISCOUNT, totalTokens * 0.1)
  const discount = totalTokens === 1 ? 1 : percentage
  return Math.round(totalTokens * discount * 100) / 100
}

const getTokens = async (userId: string): Promise<number | undefined> => {
  const query = supabase.tokens().select('total').eq('owner_id', userId)
  const result: any = await supabase.query(query)
  return result?.[0]?.total
}

export type OnTokens = (totalTokens?: number) => void
export const subscribeToTokens = (userId: string, onTokens: OnTokens) => {
  const sub = supabase
    .tokens()
    .on('*', p => p.new.owner_id === userId && onTokens(p.new.total))
    .subscribe()
  sub.onError(async () => onTokens(await getTokens(userId)))
  return sub
}

export type Lydia = {
  message: string
  amount: number
  recipient: string
  uid: string
}
export const lydia = async (params: Lydia) => {
  const result = await fetch('/api/payment/lydia', {
    method: 'post',
    body: JSON.stringify({
      message: params.message,
      amount: params.amount,
      recipient: params.recipient,
      ownerId: params.uid,
    }),
  })
  return await result.json()
}
