import React from 'react'

export const Loading = ({ style }: any) => (
  <div className="loading" style={style}>
    <div className="rectangle_1"></div>
    <div className="rectangle_2"></div>
    <div className="rectangle_3"></div>
    <div className="rectangle_4"></div>
    <div className="rectangle_5"></div>
  </div>
)
