import * as supabase from '@/libs/supabase'
import * as thread from '@/libs/thread'

let cache: Cache
let queue: string[] = []
let running = false
let actualId: string | null = null
let actual: Promise<ArrayBuffer | undefined> | null = null

const logger = (...args: any) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args)
  }
}

const warner = (...args: any) => {
  if (process.env.NODE_ENV === 'development') {
    console.warn(...args)
  }
}

const createEndpoint = (serviceId: string) => `/musics/${serviceId}`

const init = async () => (cache ||= await caches.open('raw-songs'))

const fetchFromCache = async (serviceId: string) => {
  const endpoint = createEndpoint(serviceId)
  const songRequest = new Request(endpoint)
  const response = await cache.match(songRequest)
  if (response) return response.arrayBuffer()
}

export const exists = async (serviceId: string) => {
  await init()
  const endpoint = createEndpoint(serviceId)
  const songRequest = new Request(endpoint)
  const response = await cache.match(songRequest)
  if (response) return true
  return false
}

const setInCache = async (serviceId: string, url: string) => {
  const response = await fetch(url)
  const isAudio = response.headers.get('Content-Type')?.startsWith('audio')
  if (response.status !== 200 && !isAudio) throw new Error()
  const endpoint = createEndpoint(serviceId)
  await cache.put(endpoint, response)
  return fetchFromCache(serviceId)
}

const getInfos = async (serviceId: string) => {
  const query = supabase.musics().select('*').eq('service_id', serviceId)
  const rows = await supabase.query(query)
  return rows
}

const fetchFromStorage = async (
  serviceId: string,
  shouldRetry = false,
  retryDelay = 1000
): Promise<ArrayBuffer | undefined> => {
  const params = Object.entries({ serviceId, shouldRetry })
    .map(t => t.join(': '))
    .join(', ')
  try {
    const rows = await getInfos(serviceId)
    if (rows.length > 0) {
      const [row] = rows
      const { bucket_filename } = row
      const bucket = supabase.client.storage.from('musics')
      const endpoint = `${bucket_filename}`
      const { signedURL } = await bucket.createSignedUrl(endpoint, 3600)
      if (signedURL) {
        logger(`Success on fetchFromStorage [${params}]`)
        return setInCache(serviceId, signedURL)
      }
    }
    throw new Error('Not found')
  } catch (error: any) {
    warner(`Error on fetchFromStorage [${params}]`)
    warner(error)
    const exp = supabase.client.auth.session?.()?.expires_at
    if (exp && exp <= Date.now()) await supabase.client.auth.refreshSession()
    if (shouldRetry) {
      await thread.sleep(retryDelay)
      const newDelay = Math.min(32000, retryDelay * 2)
      return fetchFromStorage(serviceId, shouldRetry, newDelay)
    }
    throw error
  }
}

export const song = async (serviceId: string) => {
  await init()
  warner(`Fetching ${serviceId}`)
  try {
    const fetched = await fetchFromCache(serviceId)
    if (fetched) return fetched
    return await fetchFromStorage(serviceId)
  } catch (error) {
    const endpoint = `/api/music/${serviceId}`
    logger(`Calling ${endpoint}`)
    const response = await fetch(endpoint)
    if (response.status === 200) {
      await thread.sleep(2000)
      return await fetchFromStorage(serviceId, true)
    }
    throw error
  }
}

const enqueuing = async (): Promise<void> => {
  const serviceId = queue.shift()
  if (serviceId) {
    actualId = serviceId
    actual = song(serviceId)
    await actual
    actualId = null
    actual = null
    await thread.sleep(1000)
    return enqueuing()
  }
  running = false
}

export const enqueue = async (serviceId: string) => {
  await init()
  const fetched = await exists(serviceId)
  if (fetched) return
  queue.push(serviceId)
  if (queue.length > 0 && !running) {
    running = true
    enqueuing()
  }
}

export const now = async (serviceId: string) => {
  await init()
  if (actualId === serviceId) {
    return await actual
  } else {
    const index = queue.findIndex(v => v === serviceId)
    if (index >= 0) queue.splice(index, 1)
    return song(serviceId)
  }
}
