import { Music, PlaylistPreview, Room } from '@/types/room'
import axios from 'axios'
import { captureException } from '@sentry/nextjs'
const jsonpAdapter = require('axios-jsonp')

export const getGoogleSuggestions = async (searchInput: string) => {
  try {
    const url = `https://suggestqueries.google.com/complete/search?client=chrome&ds=yt&q=${searchInput}`
    const results = await axios({ url, adapter: jsonpAdapter })
    return results.data[1]
  } catch (error) {
    captureException(error)
    throw error
  }
}

export const searchMusics = async (
  search: string,
  ownerId: string,
  type: 'spotify' | 'youtube-music'
) => {
  try {
    const params = new URLSearchParams({ search, ownerId })
    const endpoint = `/api/${type}/musics/search?${params.toString()}`
    const response = await fetch(endpoint)
    const data = await response.json()
    return data as Music[]
  } catch (error) {
    captureException(error)
    throw error
  }
}

export const searchPlaylists = async (search: string, ownerId: string) => {
  try {
    const params = new URLSearchParams({ search, ownerId })
    const endpoint = `/api/spotify/playlists/search?${params.toString()}`
    const response = await fetch(endpoint)
    const data = await response.json()
    return data.filter(nonEmptyPlaylist) as PlaylistPreview[]
  } catch (error) {
    captureException(error)
    throw error
  }
}

export const getPlaylist = async (id: string) => {
  try {
    const response = await fetch(`/api/spotify/playlists/${id}`)
    const data = await response.json()
    return data.filter(nonEmptyPlaylist) as Music[]
  } catch (error) {
    captureException(error)
    throw error
  }
}

const nonEmptyPlaylist = (playlistItem: any): PlaylistPreview[] =>
  playlistItem.thumbnailUrl?.includes('https://') && playlistItem.id

export type InsertToken = { room: Room; userId: string; music: Music }
export const insertToken = async ({ room, music, userId }: InsertToken) => {
  try {
    const type = room.isSpotify ? 'spotify' : 'youtube-music'
    const endpoint = `/api/${type}/add-paid-track`
    const roomId = room.id.toString()
    const { ownerId } = room
    const body = JSON.stringify({ roomId, music, userId, ownerId })
    const response = await fetch(endpoint, { method: 'POST', body })
    const data = await response.json()
    return data
  } catch (error) {
    captureException(error)
    throw error
  }
}
