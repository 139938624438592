import { Music, Track, Room, UpVote, PaidTrack } from '@/types/room'
import * as supabase from '@/libs/supabase'
import { definitions } from '@/types/dto'
import * as DixHeures from '@/libs/dix-heures'

export type CommandParams = {
  roomId: number
  trackId: number
  ownerId: string
}

export const serializeParams = (
  params: CommandParams
): Partial<definitions['up_votes']> => ({
  room_id: params.roomId,
  track_id: params.trackId,
  owner_id: params.ownerId,
})

const vote = (action: 'up' | 'down') => {
  return async (params: CommandParams) => {
    const p = serializeParams(params)
    const base = supabase.upvotes()
    const query = action === 'up' ? base.insert(p) : base.delete().match(p)
    return supabase.query(query)
  }
}
export const upVote = vote('up')
export const downVote = vote('down')

export type AddPlaylist = { musics: Music[]; room: Room; ownerId: string }
export const addPlaylist = async (params: AddPlaylist): Promise<Track[]> => {
  const { musics, room, ownerId } = params
  const room_id = room.id
  const temp = await Promise.all(
    musics.map(async music => {
      const { artist, title, id, thumbnailUrl } = music
      const thumbnail_url = thumbnailUrl
      const service_id = id
      const value = await supabase
        .tracks()
        .insert({ title, artist, thumbnail_url, room_id, service_id })
      if (value.data && value.data[0]) {
        const trackId = value.data[0].id
        await upVote({ roomId: room_id, trackId, ownerId })
        return value.data.map(deserializeTrack)
      }
      return []
    })
  )
  return temp.flat()
}

export const remove = async (roomId: number, id: number | string) => {
  return await supabase.tracks().delete().match({ room_id: roomId, id: id })
}

export const sort = (
  playlist: Track[] = [],
  upVotes: UpVote[] = [],
  paidTracks: PaidTrack[] = [],
  playing?: DixHeures.ID
) => {
  const currentTrack = playlist.find(t => t.id === playing) ?? []
  const without = playlist.filter(t => t.id !== playing)
  const paidPlaylist = without
    .filter(music => paidTracks.map(p => p.trackId).includes(music.id))
    .sort(byUpVotes(upVotes))
  const restOfThePlaylist = without
    .filter(music => !paidTracks.map(p => p.trackId).includes(music.id))
    .sort(byUpVotes(upVotes))
  const sortedPlaylist = [currentTrack, paidPlaylist, restOfThePlaylist].flat()
  return sortedPlaylist
}

const byUpVotes = (upVotes: UpVote[]) => {
  return (musicA: { id: number }, musicB: { id: number }) => {
    const totalUpVotesA = upVotes.filter(u => u.trackId === musicA.id).length
    const totalUpVotesB = upVotes.filter(u => u.trackId === musicB.id).length
    return totalUpVotesB - totalUpVotesA
  }
}

export const deserializeTrack = (payload: definitions['tracks']): Track => {
  const {
    created_at,
    updated_at,
    room_id,
    service_id,
    thumbnail_url,
    ...rest
  } = payload
  return {
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
    roomId: room_id,
    serviceId: service_id,
    thumbnailUrl: thumbnail_url,
    ...rest,
  }
}

export const get = async (roomId: number) => {
  const res = await supabase
    .tracks()
    .select('*')
    .eq('room_id', roomId)
    .order('created_at', { ascending: true })
  return (res.data ?? []).map(deserializeTrack)
}

export type SubscribeCallback = (tracks: Track[]) => void
export const subscribe = async (roomId: number, cb: SubscribeCallback) => {
  let data = await get(roomId)
  cb(data)
  const subscription = supabase
    .tracks()
    .on('*', payload => {
      if (payload.eventType === 'UPDATE') return
      const isDelete = payload.eventType === 'DELETE'
      const load = isDelete ? payload.old : payload.new
      data = isDelete
        ? data.filter(t => t.id !== load.id)
        : [...data, deserializeTrack(load)]
      cb(data)
    })
    .subscribe()
  subscription.onError(async () => cb((data = await get(roomId))))
  return subscription
}
